<template>
    <div class="baseMain">
        <div class="baseSearch clearfloat">
            <!--<div class="baseSearchOne">-->
            <!--<div class="baseSearchOneLeft">-->
            <!--<span>报名时间：</span>-->
            <!--</div>-->
            <!--<div class="baseSearchOneRight3 clearfloat">-->
            <!--<div style="height: 100%;float: left;font-size: 0;">-->
            <!--<el-radio-group v-model="searchForm.radio" size="mini">-->
            <!--<el-radio-button label="1">今日</el-radio-button>-->
            <!--<el-radio-button label="2">昨日</el-radio-button>-->
            <!--<el-radio-button label="3">近七日</el-radio-button>-->
            <!--</el-radio-group>-->
            <!--</div>-->
            <!--<div style="height: 100%;float: left;font-size: 0;margin-left: 10px;">-->
            <!--<el-date-picker v-model="searchForm.value1" type="datetimerange"-->
            <!--range-separator="-" start-placeholder="开始日期"-->
            <!--end-placeholder="结束日期"></el-date-picker>-->
            <!--</div>-->
            <!--</div>-->
            <!--</div>-->
            <div class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>报名日期：</span>
                </div>
                <div class="baseSearchOneRight3">
                    <el-date-picker v-model="searchForm.time" type="daterange" unlink-panels clearable range-separator="-"
                        start-placeholder="开始日期" end-placeholder="结束日期" value-format="YYYY-MM-DD"></el-date-picker>
                </div>
            </div>
            <div class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>报名批次：</span>
                </div>
                <div class="baseSearchOneRight">
                    <el-select v-model="searchForm.batch_no" placeholder="请选择" clearable>
                        <el-option v-for="item in batch_no_list" :key="item.value" :label="item.label"
                            :value="item.value"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="baseSearchOne">
                <el-button type="primary" style="margin-left: 20px;" @click="search">搜索</el-button>
                <el-button @click="cleanAll">清空</el-button>
            </div>
        </div>
        <div class="baseOperate clearfloat">
            <div class="baseOperateOne">
                <el-button type="primary" @click="openExport">导出记录</el-button>
            </div>
        </div>
        <div class="baseTable">
            <publicTable :publicTableOtherHeight="searchHeight" :publicTableData="tableData">
                <el-table-column prop="teacher_no" label="学工号"></el-table-column>
                <el-table-column prop="user_name" label="姓名"></el-table-column>
                <el-table-column prop="phone" label="联系方式"></el-table-column>
                <el-table-column prop="count" label="录取人数"></el-table-column>
            </publicTable>
        </div>
        <div class="basePage" style="margin-top: 4px;">
            <publicPage ref="publicPageUserStudent" :publicPageTotal="{ total: total, pageSizes: [500], pageSize: 500 }"
                @pageChange="getTableData"></publicPage>
        </div>
    </div>
</template>

<script>

import publicPage from '../../components/publicPage.vue'

import publicTable from '../../components/publicTable.vue'

import XLSX from 'xlsx';

import { businessGet } from '../../api/business.js'

import { utilsExportExcel } from '../../utils/utils.js'
import moment from "moment"
export default {
    name: "recruitRanking",
    components: {
        publicPage,
        publicTable
    },
    data() {
        return {
            batch_no_list: [],
            searchForm: {
                time: [],
                batch_no: '',
            },
            searchHeight: 300,
            tableData: [],
            total: 0,
        }
    },
    mounted() {
        this.get_batch_no_list();
        this.$nextTick(() => {
            let baseSearchDom = document.querySelector('.baseSearch');
            let baseOperateDom = document.querySelector('.baseOperate');
            let searchHeight = baseSearchDom.offsetHeight + baseOperateDom.offsetHeight;
            this.searchHeight = searchHeight;
            this.cleanAll();
        })
    },
 
    methods: {
        openExport() {
            let url = '/admin/v1/recruit_rank?is_export=1';
            for (let k in this.searchForm) {
                if (this.searchForm[k]) {
                    if (k == 'time') {
                        if (this.searchForm[k][0] && this.searchForm[k][1]) {
                            url = url + '&' + k + '=' + this.searchForm[k][0] + ' 00:00:00 - ' + this.searchForm[k][1] + ' 23:59:59';
                        }
                    }
                    else {
                        url = url + '&' + k + '=' + this.searchForm[k];
                    }
                }
            }
            utilsExportExcel(url)
        },
        exportExcel() {
            //数据表格
            var aoa = [
                ['表格在此', '', '', ''],
                ['', '', '', ''],
                ['姓名', '性别', '年龄', '注册时间'],
                ['张三', '男', 18, new Date()],
                ['李四', '女', 22, new Date()]
            ];

            //创建book
            var wb = XLSX.utils.book_new();

            var ws = XLSX.utils.aoa_to_sheet(aoa);
            //设置列宽
            ws['!cols'] = [
                { width: 50 },
                { width: 15 },
                { width: 15 },
                { width: 10 }
            ];
            ws['!merges'] = [
                // 设置A1-C1的单元格合并
                {
                    s: { r: 0, c: 0 },
                    e: { r: 1, c: 3 }
                }
            ];

            ws['A3'].l = { Target: "http://sheetjs.com", Tooltip: "Find us @ SheetJS.com!" };

            var aoa2 = [
                ['姓名', '性别', '年龄', '注册时间'],
                ['张三', '男', 18, new Date()],
                ['李四', '女', 22, new Date()]
            ];

            var ws2 = XLSX.utils.aoa_to_sheet(aoa2);

            ws2["A1"].s = {
                font: { sz: 14, bold: true, vertAlign: true },
                alignment: { vertical: "center", horizontal: "center" },
                fill: { bgColor: { rgb: "E8E8E8" }, fgColor: { rgb: "E8E8E8" } }
            };

            var aoa3 = [
                ['姓名', '性别', '年龄', '注册时间'],
                ['张三', '男', 18, new Date()],
                ['李四', '女', 22, new Date()]
            ];

            var ws3 = XLSX.utils.aoa_to_sheet(aoa3);
            ws3["!margins"] = { left: 0.25, right: 0.25, top: 0.75, bottom: 0.75, header: 0.3, footer: 0.3 }

            //sheet写入book
            XLSX.utils.book_append_sheet(wb, ws, "操作合并，列宽");
            XLSX.utils.book_append_sheet(wb, ws2, "正常");
            XLSX.utils.book_append_sheet(wb, ws3, "操作间隔");

            //输出

            var timestamp = (new Date()).getTime();
            XLSX.writeFile(wb, "file" + timestamp + ".csv");
        },
        cleanAll() {
            var year = moment().format("YYYY")
            this.searchForm = {
                time: [`${year}-01-01`, `${year}-12-31`],
                batch_no: '',
            }
            this.search();
        },
        search() {
            this.$refs.publicPageUserStudent.currentPage = 1;
            this.getTableData();
        },
        getTableData() {
            let url = '/admin/v1/recruit_rank?page=' + this.$refs.publicPageUserStudent.currentPage + '&page_pre=500'
            console.log("------------",this.searchForm)
            for (let k in this.searchForm) {
                if (this.searchForm[k]) {
                    if (k == 'time') {
                        if (this.searchForm[k][0] && this.searchForm[k][1]) {
                            url = url + '&' + k + '=' + this.searchForm[k][0] + ' 00:00:00 - ' + this.searchForm[k][1] + ' 23:59:59';
                        }
                    }
                    else {
                        url = url + '&' + k + '=' + this.searchForm[k];
                    }
                }
            }
            this.tableLoading = true;
            businessGet(url).then(res => {
                this.tableLoading = false;
                if (res.data.status == 200) {
                    this.total = res.data.data.count;
                    this.tableData = res.data.data.list;
                }
                else {
                    this.$message({
                        showClose: true,
                        message: res.data.message,
                        type: 'warning'
                    });
                }
            })
        },
        get_batch_no_list() {
            let url = '/admin/v1/sign_up?page=1&page_pre=500';
            businessGet(url).then(res => {
                if (res.data.status == 200) {
                    let newArray = [];
                    res.data.data.list.forEach(item => {
                        let a = {
                            value: item.batch_no,
                            label: item.batch_name,
                            fields: item.fields
                        }
                        newArray.push(a);
                    })
                    this.batch_no_list = newArray;
                }
                else {
                    this.$message({
                        showClose: true,
                        message: res.data.message,
                        type: 'warning'
                    });
                }
            })
        }
    }
}
</script>

<style scoped>
@import "../../assets/css/base.css";
</style>
